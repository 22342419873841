<template>
  <div class="permission_distribution">
    <div class="util_box">
      <div>
        <div class="input_box mr12 mt12" v-supAdmin>
          <el-select
            v-model="id_operator"
            placeholder="请选择"
            @change="selectOperator"
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            ></el-option>
          </el-select>
        </div>
      </div>

      <div class="add_permission">
        <i class="el-icon-plus" color="#2a47b0" @click="addRole"></i>
        <span @click="addRole">新建权限类型</span>
      </div>
    </div>
    <!-- 表格 -->
    <div class="tabel_box">
      <el-table
        :data="roleList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#fafafb',
          height: '40px'
        }"
        :stripe="true"
        size="small"
      >
        <el-table-column
          type="index"
          label="序号"
          :index="getIndex"
        ></el-table-column>
        <el-table-column
          prop="name_operator"
          label="运营商"
          width="140"
          v-if="role === 100"
        >
          <template v-slot="scope">
            <span>{{ scope.row.name_operator || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="role_name"
          label="权限类型"
          width="160"
        ></el-table-column>
        <el-table-column label="用户列表">
          <template v-slot="scope">
            <span v-for="(item, index) in scope.row.userArray" :key="index">
              {{ item.remark }}
              {{ index === scope.row.userArray.length - 1 ? '' : '|' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220">
          <template v-slot="scope">
            <template v-if="scope.row.id !== 100">
              <div class="operate">
                <span class="edit_box" @click="editRole(scope.row)">
                  <img src="../../../assets/eidt_3.png" alt="" />
                  <span>修改</span>
                </span>

                <span class="edit_box" @click="configPermission(scope.row)">
                  <img src="../../../assets/setting_3.png" alt="" />
                  <span>设置</span>
                </span>

                <span class="edit_box2" @click="delRole(scope.row)">
                  <img src="../../../assets/del_3.png" alt="" />
                  <span>删除</span>
                </span>
              </div>
            </template>
            <el-tooltip
              v-else
              content="超级管理员不允许操作"
              placement="left"
              effect="light"
            >
              <span>-</span>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="CurrentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加/修改 角色 -->
    <ChangeRole ref="changeRoleRef" @refresh="getRoleList" />

    <!-- 配置权限相关 -->
    <ConfigPermission ref="configPermissionRef" @refresh="getRoleList" />
  </div>
</template>

<script>
import { GetRoleList, EditRole, GetOperatorList } from '@/api'
import ChangeRole from './component/change_role.vue'
import ConfigPermission from './component/config_permission.vue'

export default {
  components: { ChangeRole, ConfigPermission },
  data() {
    return {
      // 用户权限类型
      role: '',
      // 运营商
      id_operator: '',
      // 运营商列表
      operatorList: [],
      page_id: 0,
      page_num: 20,
      total: 0,
      roleList: []
    }
  },
  methods: {
    // 索引
    getIndex(i) {
      return this.page_id * 10 + i + 1
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s
      this.page_id = 0
      this.getRoleList()
    },

    // 页码变化
    CurrentChange(p) {
      this.page_id = p - 1
      this.getRoleList()
    },

    // 选中运营商
    selectOperator() {
      this.page_id = 0
      this.getRoleList()
    },

    // 获取角色列表
    async getRoleList() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num
      }
      if (this.id_operator) {
        params.id_operator = this.id_operator
      }
      const { ret, data, msg } = await GetRoleList(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取角色列表失败')
      }
      this.total = data.cnt_all
      this.roleList = data.data
    },

    // 添加角色
    addRole() {
      this.$refs.changeRoleRef.add()
    },

    // 修改角色
    editRole(row) {
      this.$refs.changeRoleRef.edit(row)
    },

    // 删除角色
    async delRole(row) {
      if (row.userArray.length) {
        this.$message.warning('请先删除该权限类型下的账号信息!')
        return
      }
      const params = {
        role: row.id,
        state: 0
      }
      const { ret, data, msg } = await EditRole(params)
      if (ret !== 0) {
        return this.$message.error(msg || '删除权限类型失败')
      }
      this.$message.success('删除成功')
      this.getRoleList()
    },

    // 设置权限
    configPermission(row) {
      this.$refs.configPermissionRef.config(row)
    },

    // 获取运营商列表
    async getOperatorList() {
      const params = {}
      const { ret, data, msg } = await GetOperatorList(params)
      if (ret !== 0) {
        return this.$message.error(msg)
      }
      this.operatorList = [
        { id_operator: '', name_operator: '全部运营商' }
      ].concat(data.data)
    }
  },
  created() {
    this.role = this.$store.getters.role
    this.getRoleList()
    if (this.role === 100) {
      this.getOperatorList()
    }
  }
}
</script>

<style lang="scss" scoped>
.permission_distribution {
  min-height: calc(100vh - 106px);
  background: #fff;
}

.util_box {
  padding: 12px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .add_permission {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $theme;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    span {
      margin-left: 4px;
      font-size: 14px;
    }
  }
}

.tabel_box {
  padding: 12px 24px;

  .operate {
    display: flex;
    align-items: center;
    justify-content: center;

    .edit_box {
      margin: 0 6px;
      cursor: pointer;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #2a47b0;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }

    .edit_box2 {
      margin: 0 6px;
      cursor: pointer;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        color: #d94f4f;
      }

      img {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
</style>
