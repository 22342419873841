<template>
  <el-dialog
    title="设置权限"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
  >
    <div class="table_box">
      <div class="line_title">
        <div class="item">一级</div>
        <div class="item">二级</div>
        <div class="item">设置</div>
      </div>

      <div class="line" v-for="item in permission" :key="item.id">
        <div class="item">
          <template v-if="item.children">
            {{ item.name }}
          </template>
          <template v-else>
            <el-checkbox v-model="item.checked" @change="selected(item)">{{
              item.name
            }}</el-checkbox>
          </template>
        </div>
        <template v-if="item.children">
          <div class="item_group">
            <div class="row" v-for="it in item.children" :key="it.id">
              <el-checkbox v-model="it.checked" @change="selected(it)">{{
                it.name
              }}</el-checkbox>
            </div>
          </div>
          <div class="item_group">
            <div class="row" v-for="it in item.children" :key="it.id">
              <el-checkbox v-model="it.operate" @change="checkIsAll"
                >操作权限</el-checkbox
              >
            </div>
          </div>
        </template>
        <template v-else>
          <div class="item"></div>
          <div class="item">
            <el-checkbox v-model="item.operate" @change="checkIsAll"
              >操作权限</el-checkbox
            >
          </div>
        </template>
      </div>

      <div class="line">
        <div class="item">
          <el-checkbox v-model="checkAll" @change="changeAll">全选</el-checkbox>
        </div>
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import fullPermission from './permission.js'
import { EditRole } from '@/api'

export default {
  data() {
    return {
      // 是否显示配置权限弹窗
      dialogVisible: false,
      // 当前角色信息
      row: '',
      // 完整的权限表
      permission: [],
      // 是否全选
      checkAll: false
    }
  },
  methods: {
    // 配置权限
    config(row) {
      this.dialogVisible = true
      this.row = row
      this.permission = JSON.parse(JSON.stringify(fullPermission))
      if (row.project_menu) {
        const obj = JSON.parse(row.project_menu)
        const has = obj.has
        const operate = obj.operate
        if (has.length) {
          this.echoHas(has)
        }
        if (operate.length) {
          this.echoOperate(operate)
        }
        // 判断是否全部选中
        this.checkIsAll()
      } else {
        this.checkAll = false
      }
    },

    // 回显拥有侧边栏
    echoHas(arr) {
      this.permission.map((i) => {
        if (i.children) {
          i.children.map((j) => {
            if (arr.includes(j.id)) {
              j.checked = true
            } else {
              j.checked = false
            }
          })
        } else {
          if (arr.includes(i.id)) {
            i.checked = true
          } else {
            i.checked = false
          }
        }
      })
    },

    // 回显可操作
    echoOperate(arr) {
      this.permission.map((i) => {
        if (i.children) {
          i.children.map((j) => {
            if (arr.includes(j.id)) {
              j.operate = true
            } else {
              j.operate = false
            }
          })
        } else {
          if (arr.includes(i.id)) {
            i.operate = true
          } else {
            i.operate = false
          }
        }
      })
    },

    // 一级权限选中
    selected(item) {
      item.operate = item.checked
      this.checkIsAll()
    },

    // 判断是否全部选中
    checkIsAll() {
      let countFalse = 0
      this.permission.map((i) => {
        if (i.children) {
          i.children.map((j) => {
            if (!j.operate || !j.checked) {
              countFalse++
            }
          })
        } else {
          if (!i.operate || !i.checked) {
            countFalse++
          }
        }
      })
      if (countFalse > 0) {
        this.checkAll = false
      } else {
        this.checkAll = true
      }
    },

    // 全选发生变化
    changeAll(flag) {
      this.permission.map((i) => {
        if (i.children) {
          i.children.map((j) => {
            j.checked = j.operate = flag
          })
        } else {
          i.checked = i.operate = flag
        }
      })
    },

    // 获取选中的id
    getSelectedIds(data, arr) {
      data.map((i) => {
        if (i.children) {
          this.getSelectedIds(i.children, arr)
        } else {
          if (i.checked) {
            arr.push(i.id)
          }
        }
      })
      return arr
    },

    // 获取可操作的
    getOperate(data, arr) {
      data.map((i) => {
        if (i.children) {
          this.getOperate(i.children, arr)
        } else {
          if (i.operate) {
            arr.push(i.id)
          }
        }
      })
      return arr
    },

    // 确定分配权限
    async confirm() {
      const has = this.getSelectedIds(this.permission, [])
      const operate = this.getOperate(this.permission, [])
      const str = JSON.stringify({ has, operate })
      const params = {
        role: this.row.id,
        project_menu: str
      }
      const { ret, data, msg } = await EditRole(params)
      if (ret !== 0) {
        return this.$message.error(msg || '授权失败!')
      }
      this.$message.success('授权成功')
      this.dialogVisible = false
      this.$emit('refresh')
    }
  }
}
</script>

<style lang='scss' scoped>
.table_box {
  width: 100%;
  border-top: 1px solid #eff2f7;
  border-left: 1px solid #eff2f7;

  .line_title {
    min-height: 42px;
    display: flex;

    .item {
      flex: 1 1 33.333%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #eff2f7;
      border-bottom: 1px solid #eff2f7;
      box-sizing: border-box;
    }
  }

  .line {
    min-height: 42px;
    display: flex;

    .item {
      flex: 1 1 33.333%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid #eff2f7;
      border-bottom: 1px solid #eff2f7;
      padding-left: 80px;
    }

    .item_group {
      flex: 1 1 33.333%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .row {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        padding-left: 80px;
        border-right: 1px solid #eff2f7;
        border-bottom: 1px solid #eff2f7;
      }
    }
  }
}
</style>
