export default [
  // {
  //   id: 1,
  //   name: '数据中心',
  //   checked: false,
  //   operate: false
  // },
  {
    id: 2,
    name: '系统列表',
    checked: false,
    operate: false
  },
  {
    id: 3,
    name: '项目信息',
    checked: false,
    operate: false
  },
  {
    id: 4,
    name: '异常统计',
    checked: false,
    operate: false
  },
  {
    id: 5,
    name: '系统管理',
    children: [
      {
        id: 5001,
        name: '未配置系统',
        checked: false,
        operate: false
      },
      {
        id: 5002,
        name: '已配置系统',
        checked: false,
        operate: false
      }
    ]
  },
  {
    id: 6,
    name: '预设参数',
    checked: false,
    operate: false
  },
  {
    id: 7,
    name: '预警设置',
    children: [
      {
        id: 7001,
        name: '默认预警',
        checked: false,
        operate: false
      },
      {
        id: 7002,
        name: '自定义预警',
        checked: false,
        operate: false
      }
    ]
  },
  {
    id: 8,
    name: '运营商管理',
    checked: false,
    operate: false
  },
  {
    id: 9,
    name: '用户权限',
    children: [
      {
        id: 9001,
        name: '用户管理',
        checked: false,
        operate: false
      },
      {
        id: 9002,
        name: '权限分配',
        checked: false,
        operate: false
      }
    ]
  }
]
