<template>
  <el-dialog
    :title="isEdit ? '修改权限' : '新建权限'"
    :visible.sync="dialogVisible"
    width="600px"
    :close-on-click-modal="false"
    @closed="resetForm"
  >
    <el-form :model="ruleForm" :rules="rules" ref="roleRef" label-width="100px">
      <template v-if="role === 100">
        <el-form-item label="运营商：" prop="id_operator">
          <el-select
            v-model="ruleForm.id_operator"
            placeholder="请选择"
            style="width: 100%"
            :disabled="isEdit ? true : false"
          >
            <el-option
              v-for="item in operatorList"
              :key="item.id_operator"
              :label="item.name_operator"
              :value="item.id_operator"
            ></el-option>
          </el-select>
        </el-form-item>
      </template>

      <el-form-item label="权限类型：" prop="role_name">
        <el-input
          v-model.trim="ruleForm.role_name"
          maxlength="20"
          placeholder="请输入权限名称"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetOperatorData, AddRole, EditRole } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 是否为修改模式
      isEdit: false,
      // 绝色
      role: '',
      // 运营商列表
      operatorList: [],
      // 规则表单
      ruleForm: {
        id: '',
        id_operator: '',
        role_name: ''
      },
      rules: {
        id_operator: [
          { required: true, message: '请选择运营商', trigger: 'change' }
        ],
        role_name: [
          { required: true, message: '请输入权限名称', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 添加权限类型
    add() {
      this.isEdit = false
      this.role = this.$store.getters.role
      if (this.role === 100) {
        this.getOperator()
      }
      this.dialogVisible = true
    },

    // 修改角色
    edit(row) {
      this.ruleForm.id = row.id
      this.isEdit = true
      this.role = this.$store.getters.role
      if (this.role === 100) {
        this.getOperator()
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.ruleForm.id_operator = row.id_operator
        this.ruleForm.role_name = row.role_name
      })
    },

    // 重置表单
    resetForm() {
      this.$refs.roleRef.resetFields()
    },

    // 获取运营商列表
    async getOperator() {
      const params = {}
      const { ret, data, msg } = await GetOperatorData(params)
      if (ret !== 0) {
        return this.$message.error(msg || '获取运营商数据')
      }
      this.operatorList = data.data
    },

    // 确定
    confirm() {
      this.$refs.roleRef.validate(async (_) => {
        if (!_) return
        if (this.isEdit) {
          this.saveChange()
        } else {
          this.addRole()
        }
      })
    },

    // 添加权限类型
    async addRole() {
      const params = {
        role_name: this.ruleForm.role_name
      }
      if (this.role === 100) {
        params.id_operator = this.ruleForm.id_operator
      }
      const { ret, data, msg } = await AddRole(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加角色失败')
      }
      this.$message.success('添加成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 保存修改
    async saveChange() {
      const params = {
        role: this.ruleForm.id,
        role_name: this.ruleForm.role_name
      }
      const { ret, data, msg } = await EditRole(params)
      if (ret !== 0) {
        return this.$message.error(msg || '修改角色失败!')
      }
      this.$message.success('更新成功')
      this.$emit('refresh')
      this.dialogVisible = false
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
